'use strict';

angular.module('appApp')
  .config(function($stateProvider) {
    $stateProvider
      .state('legal-notes', {
        url: '/legal-notes',
        templateUrl: 'app/legal-notes/legal-notes.html',
        controller: 'LegalNotesCtrl'
      });
  });
